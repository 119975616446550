// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CompanyForm from "./component/CompanyForm";
import BusinessAssessment from "./component/BusinessAssessment";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<CompanyForm />} />
          <Route path="/business-assessment" element={<BusinessAssessment />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
